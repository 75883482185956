import store from '../store'
import { SET_USER, DEL_USER, MERGE_USER } from '../actions/types'
export const isBrowser = () => typeof window !== "undefined"

// const SERVER_HOST = "http://localhost:3000"
const languages = ["es", "en"]; //TODO: Confirmar que sea "es" y no "en"
/*TODO: Limpiar el flujo
  Idealmente:
    *Si el usuario no tiene grupos de workers asignados, se genera su grupo
    *Si el usuario no existe en el backend, se crea en el backend
    *No se vuelven a crear usuarios que ya existían
    *No se satura con requests innecesarias ni a Google ni al backend
*/
const SERVER_HOST = "https://lomito-city-backend.herokuapp.com"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : null;

export const getQuestion = () => {
  const publicName = JSON.parse(window.localStorage.getItem('datacomputeWorker')).result.name;
  window.gapi.client.datacompute.workspaces.leaseQuestions({ 'workerName': publicName })
    .then((resp) => {
      if (resp.result.leases) {
        let questionLeases = resp.result.leases;
        let lease = questionLeases[questionLeases.length-1];
        window.localStorage.setItem('question', JSON.stringify(lease))
        // answerQuestionFromLease(questionLeases[0]);
        // let currentQuestionName = lease.questionName;
        getPayloadList(lease); //only for validations
      }
    })
    .catch(err => {
      console.log("Unable to lease questions: ", err);
    });
}

export const getPayloadList = (lease) => {
  //let lease = JSON.parse(window.localStorage.getItem('question'));
  let currentQuestionName = lease.questionName;
  window.gapi.client
    .request({
      'path': `https://content-datacompute.googleapis.com/v1/${
        formatURL(currentQuestionName)}:listQuestionPayloads?pageSize=10`,
      'method': 'GET',
    })
    .then(function (resp) {
      if (resp.result.payloads) {
        let data = getDataFromArgsJsonResult(resp.result.payloads.find((payload) => {
          return payload.name && payload.name == 'args.json';
        }));
        
        //TODO: Joss, ¿podemos enviar esto a Validar.js?
        //La idea sería que se pasara para allá y que se procesaran todas
        //las coordenadas con la función reconvertCoords.
        // Do something with the payload "data".
      }
    });
}

export const getDataFromArgsJsonResult = (argsJsonResult) => {
  if (argsJsonResult) {
    let uriData = argsJsonResult.uri.split('base64,');
    if (uriData.length >= 2) {
      let data = atob(uriData[1]);
      return JSON.parse(data);
    }
  }
  return JSON.parse("{}");
}

export const submitAnswer = (answerJSON) => {
  const publicName = JSON.parse(window.localStorage.getItem('datacomputeWorker')).result.name;
  const currentQuestionName = JSON.parse(window.localStorage.getItem('question')).questionName;
  window.gapi.client
    .request({
      'path': `https://datacompute.googleapis.com/v1/${
        formatURL(currentQuestionName)}:submitAnswer?`,
      'method': 'POST',
      'body': {
        'userName': publicName,
        'clientAnswerData': {
          //TODO: Confirmar que esto funcione
          'answerData': btoa(
            JSON.stringify(answerJSON)
            )
        }
      }
    })
    .then((resp) => {
      console.log("Success!");
      console.log(resp);
    }
    );
}

const formatURL = (url) => {
  return url.replace(/\+/g, '%2B');
}

const createWorkerForUser = (email) => {
  // let publicName;
  const { datacompute } = window.gapi.client;
  datacompute.worker_pools.workers
    .create(
      { 'parent': 'worker_pools/public' },
      { 'emailAddress': email, 'capabilities': { 'languageCodes': languages } }
    )
    .then(resp => {
      window.localStorage.setItem("datacomputeWorker", JSON.stringify(resp));
      // let index = resp.result.workerPoolIds.indexOf('public');
      // let workerName = resp.result.identities[index].name; //Obtener workerID
      //Almacenamos tu info en localstorage
      // window.localStorage.setItem("datacomputeWorker", JSON.stringify(workerName));
    })
    .catch(err => {
      console.log(err);
    })
}

export const modifyAssignment = (workerName, typesToAdd, typesToRemove) => {
  return (window.gapi.client.datacompute.worker_pools.workers
      .modifyAssignments(
          {'name': workerName}, {'addedQuestionTypes': typesToAdd, 'removedQuestionTypes': typesToRemove}))
      // .then(function(resp) {
      //   console.log(resp)
      //   console.log("Modified")
      //   // Handle modification. Empty for now.
      // });
}

// const getQuestionTypesToRemove = (workerResult) => {
//   if (!workerResult.assignments || !workerResult.assignments.questionTypes)
//     return [];
//   return workerResult.assignments.questionTypes.filter((type) => type != QUESTION_TYPE);
// }

//TODO: Cambiar
export const createUserInBackend = (user) => {
  
  if (user.w3) {
    const name = user.w3.ig; //TODO: ¿Esto se queda así? considerar name de datacompute en schema
    const email = user.w3.U3; //Obtener email de user
    const authId = user.w3.Eea; //Obtener auth ID único del user
    const hasGrantedScopes = (user.Zi.scope.split(" ").indexOf('https://www.googleapis.com/auth/crowd_compute.worker') === -1) ? false : true;

    //Revisa que nos den permiso de lo de la pregunta, porque no es "required"
    const data = {
      name,
      authId,
      email,
      hasGrantedScopes
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json" //Aguas con esto, es super importante
      },
      body: JSON.stringify(data)
    }

    if (hasGrantedScopes) {
      console.log("Created user in server");
      return (fetch(`${SERVER_HOST}/user/in`, options))
      // .then(res => {
      //   res.json()
      //     .then(jsn => console.log(jsn.message))
      //     .catch(err => console.log(err));
      // })
      // .catch(err => console.log(err));
    }
  }
}

export const getUserFromBackend = (user) => {
  let email;
  if (user.w3) {
    email = user.w3.U3; //Obtener email de user
  }
  const data = {
    email
  }
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json" //Aguas con esto, es super importante
    },
    body: JSON.stringify(data)
  }
  return fetch(`${SERVER_HOST}/user/find`, options)
  // .then(response => {
  //   response.json() //TODO: Revisar
  //   .then(backendUser => {
  //     return (backendUser._id ? backendUser : null)  //Si no hay id, retorna null
  //   })
  //   .catch(err => console.log(err));
  // })
  // .catch(err => console.log(err));
}

const setUser = user => {
  if (Object.keys(user).length > 0) {
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))
  const { gapi } = window; //Permite interactuar con la api de google desde window
  gapi.client.datacompute.getUserContext()
    .then(res => {
      if (res.result[`identities`]) { // obtener al worker
        //Caso 1: Ya estás dado de alta en datacompute
        let index = res.result.workerPoolIds.indexOf('public');
        let workerName = res.result.identities[index].name; //Obtener workerID
        //Almacenamos tu info en localstorage (tu info es tu public name)
        //Get worker
        gapi.client.datacompute.worker_pools.workers
          .get({ 'name': workerName, 'includeAssignments': true })
          .then((resp) => {
            window.localStorage.setItem("datacomputeWorker", JSON.stringify(resp));
          });
      } else { //Generar al worker
        //Caso 2: No estás dado de alta en datacompute
        //Creamos tu worker en Public
        createWorkerForUser(user.w3.U3) //user.w3.U3 es el email
      }

      //Solo para propósitos de nuestra app
      //Si ya estás dado de alta en backend, te obtenemos
      //No te mandamos al tutorial, te mandamos a main selection
      //Desde main selection agarramos el question type
      //Te asignamos el question type
      //Si es create route generamos el json, generamos la pregunta y la mandamos
      //Si es validate route, obtenemos el lease, sacamos el payload, lo parseamos
      //Lo pasamos a /validar, recuperamos tu respuesta y la mandamos
      //Si no, te generamos
      //Te mandamos al tutorial



      // checkUser(user, res)
    })
    .catch(err => console.log("Hubo un error: ", err))
  }
  else {
    window.localStorage.removeItem("gatsbyUser");
  }
}

const clearUser = () => {
  window.localStorage.removeItem('gatsbyUser');
  window.localStorage.removeItem('question');
  //window.localStorage.setItem("gatsbyUser", JSON.stringify({}));
  store.dispatch({ type: DEL_USER, payload: null })
}

export const handleLogin = user => {
  if (user.El === null) {
    clearUser()
  } else {
    setUser(user)
    store.dispatch({ type: SET_USER, payload: user.w3 })
  }
}

export const mergeUser = user => {
  store.dispatch({ type: MERGE_USER, payload: user})
}

export const isLoggedIn = () => {
  return store.getState.auth !== {}
}

export const logout = () => {
  const { GoogleAuth } = window
  GoogleAuth.signOut();
  setUser({});
  window.localStorage.removeItem('question');
  store.dispatch({ type: DEL_USER, payload: null })
}
