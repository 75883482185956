import { ADD_MARKER, CLEAR_MARKERS } from '../actions/types'

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_MARKER:
      return [
        ...state,
        action.payload
      ]
    case CLEAR_MARKERS:
      return []
    default:
      return state
  }
}