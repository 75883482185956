import { handleLogin, mergeUser, getUserFromBackend } from './src/services/auth'

var SCOPES = 'https://www.googleapis.com/auth/crowd_compute.worker https://www.googleapis.com/auth/userinfo.email'
var GoogleAuth

const new_script = src => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load',()=> resolve())
    script.addEventListener('error', e => reject(e))
    document.body.appendChild(script)
  })
}
    
const initClient = async () => {
  await window.gapi.client
      .init({
        'apiKey': process.env.GATSBY_GOOGLE_API_KEY,
        'discoveryDocs': [process.env.GATSBY_GOOGLE_DISCOVERY],
        'clientId': process.env.GATSBY_GOOGLE_CLIENT_ID,
        'scope': SCOPES
      })
      GoogleAuth = window.gapi.auth2.getAuthInstance()
      GoogleAuth.isSignedIn.listen(setSigninStatus)
      window.GoogleAuth = GoogleAuth
      setSigninStatus()
}

const setSigninStatus = async () => {
  var user = GoogleAuth.currentUser.get()
  var isAuthorized = user.hasGrantedScopes(SCOPES)
  if (isAuthorized) {
    handleLogin(user)
    const result = await getUserFromBackend(user)
    const backendUser = await result.json()
    mergeUser(backendUser)
  } else {
    
  }
}

// console.log('cargando')
new_script('https://apis.google.com/js/api.js')
  .then(res => window.gapi.load('client:auth2', initClient))
new_script(`https://maps.google.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_KEY}`)
  .then(res => console.log("Maps loaded"))