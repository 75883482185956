import { SET_USER, DEL_USER, MERGE_USER } from '../actions/types'

const initialState = null

export default (state = initialState, action) => {
  
  switch (action.type) {
    case SET_USER:
      
      return action.payload
    case MERGE_USER:
      return ({
        ...state,
        ...action.payload
      })
    case DEL_USER:
      return null
    default:
      return state
  }
}